import React, {Suspense, lazy} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {Spinner, Container} from 'react-bootstrap';
import SecureRoute from './components/SecureRoute';
import {mapAuthPathsToSecureRouteComps} from './constants/routes';
import {ROUTES} from '@/app/constants/routes';

const Login = lazy(() => import('./pages/Login'));
const Landing = lazy(() => import('./pages/Landing'));
const ResetPasswordEmail = lazy(() => import('./pages/ResetPasswordEmail'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const Error404 = lazy(() => import('./pages/Error/Error404'));
const Error500 = lazy(() => import('./pages/Error/Error500'));

export default function AppRoutes() {
  return (
    <Suspense
      fallback={
        <Container className="d-flex justify-content-center align-items-center">
          <Spinner animation="grow" />
        </Container>
      }
    >
      <Switch>
        {/* public paths */}
        <Route path={ROUTES.Login} component={Login} />
        <Route path={ROUTES.Landing} component={Landing} />
        <Route path={ROUTES.ResetPasswordEmail} component={ResetPasswordEmail} />
        <Route path={ROUTES.CheckSignedUrlResetPass} component={ResetPassword} />
        <Route path={ROUTES.Error404} component={Error404} />
        <Route path={ROUTES.Error500} component={Error500} />
        {/* Auth paths */}
        {[...mapAuthPathsToSecureRouteComps].map(([route, Comp], idx) => (
          <SecureRoute key={idx} exact path={route} component={Comp} />
        ))}
        <Redirect to={ROUTES.Login} />
      </Switch>
    </Suspense>
  );
}

import {AnyAction, combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';
import {persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rememberMeReducer from './auth';
import {api} from '../services/api';
import {InitialState} from './auth/types';
import {PersistPartial} from 'redux-persist/es/persistReducer';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [api.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, rememberMeReducer);

const rootReducers = combineReducers({
  [api.reducerPath]: api.reducer,
  rememberMe: persistedReducer,
});

const store = configureStore({
  reducer: rootReducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
});
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;

export type RootStore = ReturnType<typeof store.getState>;

export default store;

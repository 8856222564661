import React from 'react';
// import { Trans } from 'react-i18next';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
            Copyright ©{' '}
            <a href="https://www.ziggyclube.com/" target="_blank" rel="noopener noreferrer">
              Ziggy Club{' '}
            </a>
            2021
          </span>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
            Power by{' '}
            <a href="https://www.chkoutme.com/" target="_blank" rel="noopener noreferrer">
              {' '}
              Chkout.me{' '}
            </a>{' '}
          </span>
        </div>
      </div>
    </footer>
  );
}

import {RootStore} from '@/app/store';
import {TobacconistSchema} from '@/app/validations';
import {createApi, BaseQueryFn} from '@reduxjs/toolkit/query/react';
import axios, {AxiosRequestConfig, AxiosError} from 'axios';
import {
  Endpoints,
  ProductResponse,
  CategoryResponse,
  CustomSerializedError,
  ValidationErrors,
  GenericResponse,
  RewardResponse,
  TobacconistResponse,
  ZipCodeResponse,
  GeoLocation,
  GeoLocationRequest,
  CategoryRequest,
  ShippingResponse,
  Address,
  AdminProfileRequest,
  MonthLimitResponse,
  AdminProfileResponse,
  ApiResponse,
  SuccessResponse,
  ShippingCountResponse,
  GenericCountResponse,
  TobacconistCountResponse,
  Pagination,
  PaginationProps,
  AwardCodeResponse,
} from './types';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // baseURL: "https://ziggy-backend-2.onrender.com/api",
  headers: {
    'Content-type': 'application/json',
  },
  withCredentials: true,
  timeout: 60000,
});

export const setAxiosToken = (token: string) =>
  (axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`);

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: Endpoints;
      method: AxiosRequestConfig['method'];
      data?: any;
    },
    unknown,
    CustomSerializedError
  > =>
  async ({url, method, data}, {getState}) => {
    try {
      const result = await axiosInstance({
        url,
        method,
        data,
        headers: {
          Authorization: `Bearer ${(getState() as RootStore)?.rememberMe?.authState?.token || ''}`,
        },
      });
      return {data: result.data};
    } catch (axiosError) {
      const err = axiosError as AxiosError<{status: string; message: {errors: ValidationErrors[]}}>;
      return {
        error: {
          code: err.response?.data.status ?? err.code,
          message:
            err.response?.data.message.errors ?? (err.response?.data.message as unknown as string) ?? err.message,
        },
      };
    }
  };

export const api = createApi({
  reducerPath: 'ziggy-api',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Product', 'Reward', 'Tobacconist', 'Categories', 'Shipping', 'Profile', 'AwardCode'],
  endpoints(build) {
    return {
      getShippings: build.query<Pagination<ShippingResponse>, PaginationProps>({
        query: ({page, search}) => ({url: `shipping/?page=${page}&name=${search}`, method: 'get'}),
        providesTags: ['Shipping'],
      }),
      getShippingsCount: build.query<ShippingCountResponse, void>({
        query: () => ({url: 'shipping/count', method: 'get'}),
      }),
      getTobacconistsCount: build.query<TobacconistCountResponse, void>({
        query: () => ({url: 'users/tobacconists/count', method: 'get'}),
      }),
      getExchangesCount: build.query<GenericCountResponse, void>({
        query: () => ({url: 'users/exchanges/count', method: 'get'}),
      }),
      getCustomersCount: build.query<GenericCountResponse, void>({
        query: () => ({url: 'users/customers/count', method: 'get'}),
      }),
      getProductsCount: build.query<GenericCountResponse, void>({
        query: () => ({url: 'products/count', method: 'get'}),
      }),
      getRewardsCount: build.query<GenericCountResponse, void>({
        query: () => ({url: 'rewards/count', method: 'get'}),
      }),
      getRewardsCategoriesCount: build.query<GenericCountResponse, void>({
        query: () => ({url: 'rewards/categories/count', method: 'get'}),
      }),
      getConfirmedCustomersCount: build.query<GenericCountResponse, void>({
        query: () => ({url: 'users/customers/confirmed/count', method: 'get'}),
      }),
      sendResetPassEmail: build.mutation<GenericResponse, {email: string}>({
        query: data => ({url: 'auth/management/reset-password', method: 'post', data}),
      }),
      verifySignedUrlResetPass: build.mutation<GenericResponse, {password: string; signedUrl: string}>({
        query: data => ({
          url: `auth/management/reset-password/${data.signedUrl}`,
          method: 'post',
          data: {password: data.password},
        }),
      }),
      getCustomerAddress: build.mutation<Address, string>({
        query: id => ({url: `shipping/address/${id}`, method: 'get'}),
      }),
      getCustomerSwapRewards: build.mutation<RewardResponse['reward'][], {listOfIds: string[]}>({
        query: data => ({url: `shipping/swap`, method: 'post', data}),
      }),
      getCustomerMonthLimit: build.query<MonthLimitResponse, void>({
        query: data => ({url: 'users/admins/limits/customer', method: 'get'}),
      }),
      changeCustomerMonthLimit: build.mutation<GenericResponse, {limit: number}>({
        query: data => ({url: 'users/admins/limits/customer', method: 'patch', data}),
      }),
      updateShippingStatus: build.mutation<GenericResponse, {id: number; delivered: boolean}>({
        query: data => ({url: 'shipping', method: 'patch', data}),
        invalidatesTags: ['Shipping'],
      }),
      getAdminProfile: build.query<AdminProfileResponse, void>({
        query: () => ({url: 'users/admins', method: 'get'}),
        providesTags: ['Profile'],
      }),
      updateAdminProfile: build.mutation<GenericResponse, AdminProfileRequest>({
        query: data => ({url: 'users/admins', method: 'patch', data}),
        invalidatesTags: ['Profile'],
      }),
      getZipCode: build.mutation<ZipCodeResponse, string | number>({
        query: zipcode => ({url: `zipcode/${zipcode}`, method: 'get'}),
      }),
      getTobacconistCoord: build.mutation<GeoLocation[], GeoLocationRequest>({
        query: data => ({url: 'users/tobacconists/coord', method: 'post', data}),
      }),
      getProducts: build.query<Pagination<ProductResponse['product']>, PaginationProps>({
        query: ({page, search}) => ({
          url: `products/?page=${page}&name=${search}`,
          method: 'get',
        }),
        providesTags: ['Product'],
      }),
      getProductCategories: build.query<Pagination<CategoryResponse>, PaginationProps>({
        query: ({page, search}) => ({url: `products/categories/?page=${page}&name=${search}`, method: 'get'}),
        providesTags: ['Categories'],
      }),
      getAllProductCategories: build.query<Pagination<CategoryResponse>, void>({
        query: () => ({url: `products/categories/?page=${1}&limit=${100000000}`, method: 'get'}),
        providesTags: ['Categories'],
      }),
      addProduct: build.mutation<ProductResponse, FormData>({
        query: data => ({url: 'products', method: 'post', data}),
        invalidatesTags: ['Product'],
      }),
      updateProduct: build.mutation<GenericResponse, {body: FormData; barcode: string}>({
        query: data => ({url: `products/${data.barcode}`, method: 'patch', data: data.body}),
        invalidatesTags: ['Product'],
      }),
      deleteProduct: build.mutation<GenericResponse, {barcode: string}>({
        query: data => ({url: `products/${data.barcode}`, method: 'delete'}),
        invalidatesTags: ['Product'],
      }),
      getAwardCodes: build.query<Pagination<AwardCodeResponse['code']>, PaginationProps>({
        query: ({page, search}) => ({
          url: `award-codes/?page=${page}&name=${search}`,
          method: 'get',
        }),
        providesTags: ['AwardCode'],
      }),
      addAwardCode: build.mutation<AwardCodeResponse, FormData>({
        query: data => ({url: 'award-codes', method: 'post', data}),
        invalidatesTags: ['AwardCode'],
      }),
      // updateProduct: build.mutation<GenericResponse, {body: FormData; code: string}>({
      //   query: data => ({url: `products/${data.barcode}`, method: 'patch', data: data.body}),
      //   invalidatesTags: ['Product'],
      // }),
      deleteAwardCode: build.mutation<GenericResponse, {code: string}>({
        query: data => ({url: `award-codes/${data.code}`, method: 'delete'}),
        invalidatesTags: ['AwardCode'],
      }),
      getRewards: build.query<Pagination<RewardResponse['reward']>, PaginationProps>({
        query: ({page, search}) => ({url: `rewards/?page=${page}&name=${search}`, method: 'get'}),
        providesTags: ['Reward'],
      }),
      getAllRewardCategories: build.query<Pagination<CategoryResponse>, PaginationProps>({
        query: () => ({url: `rewards/categories/?page=${1}&limit=${100000000}`, method: 'get'}),
        providesTags: ['Categories'],
      }),
      getRewardCategories: build.query<Pagination<CategoryResponse>, PaginationProps>({
        query: ({page, search}) => ({url: `rewards/categories/?page=${page}&name=${search}`, method: 'get'}),
        providesTags: ['Categories'],
      }),
      addReward: build.mutation<RewardResponse, FormData>({
        query: data => ({url: 'rewards', method: 'post', data}),
        invalidatesTags: ['Reward'],
      }),
      updateReward: build.mutation<GenericResponse, {body: FormData; barcode: string}>({
        query: data => ({url: `rewards/${data.barcode}`, method: 'patch', data: data.body}),
        invalidatesTags: ['Reward'],
      }),
      deleteReward: build.mutation<GenericResponse, {barcode: string}>({
        query: data => ({url: `rewards/${data.barcode}`, method: 'delete'}),
        invalidatesTags: ['Reward'],
      }),
      createCategory: build.mutation<CategoryResponse, CategoryRequest>({
        query: data => ({url: `${data.category}/categories`, method: 'post', data: data.body}),
      }),
      updateCategory: build.mutation<CategoryResponse, CategoryRequest>({
        query: data => ({url: `${data.category}/categories/${data.id}`, method: 'patch', data: data.body}),
        invalidatesTags: ['Categories'],
      }),
      deleteCategory: build.mutation<CategoryResponse, Required<Omit<CategoryRequest, 'body'>>>({
        query: data => ({url: `${data.category}/categories/${data.id}`, method: 'delete'}),
        invalidatesTags: ['Categories'],
      }),
      getTobacconists: build.query<Pagination<TobacconistResponse['tobacconist']>, PaginationProps>({
        query: ({page, search}) => ({
          url: `users/tobacconists/?page=${page}&cnpj=${search}`,
          method: 'get',
        }),
        providesTags: ['Tobacconist'],
      }),
      registerTobacconist: build.mutation<TobacconistResponse['tobacconist'], TobacconistSchema>({
        query: data => ({url: 'users/tobacconists', method: 'post', data}),
        invalidatesTags: ['Tobacconist'],
      }),
      updateTobacconist: build.mutation<GenericResponse, {body: {validated: boolean}; id: string}>({
        query: data => ({url: `users/tobacconists/${data.id}`, method: 'patch', data: data.body}),
        invalidatesTags: ['Tobacconist'],
      }),
      signIn: build.mutation<Omit<AdminProfileResponse, 'user'>, Credentials>({
        query: credentials => ({
          url: 'users/admins/login',
          method: 'post',
          data: credentials,
        }),
      }),
      logout: build.mutation<GenericResponse, void>({
        query: () => ({
          url: 'users/admins/logout',
          method: 'post',
        }),
      }),
    };
  },
});

export function isSuccessResponse<T extends Record<string, any>>(value: ApiResponse<T>): value is SuccessResponse<T> {
  if (value?.data) return true;
  return false;
}

export const {
  useGetRewardsQuery,
  useGetProductsQuery,
  useGetAwardCodesQuery,
  useGetShippingsQuery,
  useGetTobacconistsQuery,
  useGetRewardsCountQuery,
  useGetAdminProfileQuery,
  useGetProductsCountQuery,
  useGetShippingsCountQuery,
  useGetExchangesCountQuery,
  useGetCustomersCountQuery,
  useGetRewardCategoriesQuery,
  useGetProductCategoriesQuery,
  useGetTobacconistsCountQuery,
  useGetCustomerMonthLimitQuery,
  useGetAllRewardCategoriesQuery,
  useGetAllProductCategoriesQuery,
  useGetRewardsCategoriesCountQuery,
  useGetConfirmedCustomersCountQuery,
  useChangeCustomerMonthLimitMutation,
  useVerifySignedUrlResetPassMutation,
  useGetCustomerSwapRewardsMutation,
  useUpdateShippingStatusMutation,
  useGetTobacconistCoordMutation,
  useUpdateAdminProfileMutation,
  useGetCustomerAddressMutation,
  useRegisterTobacconistMutation,
  useSendResetPassEmailMutation,
  useUpdateTobacconistMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useCreateCategoryMutation,
  useDeleteProductMutation,
  useUpdateRewardMutation,
  useUpdateProductMutation,
  useDeleteAwardCodeMutation,
  useDeleteRewardMutation,
  useAddRewardMutation,
  useGetZipCodeMutation,
  useAddProductMutation,
  useAddAwardCodeMutation,
  useSignInMutation,
  useLogoutMutation,
} = api;

import {lazy} from 'react';
import {useHistory} from 'react-router-dom';

export const enum ROUTES {
  Login = '/pages/login',
  Landing = '/pages/landing',
  ChangePassword = '/pages/admin/change-password',
  AdminProfile = '/pages/admin/profile',
  ResetPasswordEmail = '/pages/reset-password',
  CheckSignedUrlResetPass = '/pages/verify/:email/:signature',
  Error404 = '/pages/error-404',
  Error500 = '/pages/error-500',
  Dashboard = '/pages/dashboard',
  AddCategory = '/pages/categories/add',
  ListRewardsCategories = '/pages/categories/rewards',
  ListProductsCategories = '/pages/categories/products',
  AddProduct = '/pages/products/add',
  ListProducts = '/pages/products',
  AddAwardCode = '/pages/award-code/add',
  ListAwardCodes = '/pages/award-codes',
  AddReward = '/pages/rewards/add',
  ListRewards = '/pages/rewards',
  AddTobacconist = '/pages/tobacconists/add',
  ListTobacconist = '/pages/tobacconists',
  AddPromo = '/pages/promos/add',
  ListPromos = '/pages/promos',
  CustomerHistory = '/pages/exchanges/customers',
  PromosHistory = '/pages/exchanges/promos',
  CustomerLimits = '/pages/limits/customer',
  TobacconistLimits = '/pages/limits/tobacconist',
}

type Routes = `${ROUTES}`;

export const useRoutesHistory = () => useHistory<Routes>();

const Dashboard = lazy(() => import('../pages/Dashboard'));
const ChangePassword = lazy(() => import('../pages/ChangePassword'));
const AdminProfile = lazy(() => import('../pages/Profile'));
const AddProduct = lazy(() => import('../pages/Products/AddProduct'));
const ListProducts = lazy(() => import('../pages/Products/ListProducts'));
const AddAwardCode = lazy(() => import('../pages/AwardCode/AddAwardCode'));
const ListAwardCodes = lazy(() => import('../pages/AwardCode/ListAwardCodes'));
const AddReward = lazy(() => import('../pages/Rewards/AddReward'));
const ListRewards = lazy(() => import('../pages/Rewards/ListRewards'));
const AddTobacconist = lazy(() => import('../pages/Tobacconists/AddTobacconist'));
const ListTobacconists = lazy(() => import('../pages/Tobacconists/ListTobacconists'));
const AddPromo = lazy(() => import('../pages/Promos/AddPromo'));
const ListPromos = lazy(() => import('../pages/Promos/ListPromos'));
const AddCategory = lazy(() => import('../pages/Categories/AddCategory'));
const ListRewardsCategories = lazy(() => import('../pages/Categories/ListRewardsCategories'));
const ListProductsCategories = lazy(() => import('../pages/Categories/ListProductsCategories'));
const CustomersExchanges = lazy(() => import('../pages/Exchanges/Customers'));
const CustomerLimits = lazy(() => import('../pages/Limits/CustomerLimits'));
const TobacconistLimits = lazy(() => import('../pages/Limits/TobacconistLimits'));
const PromoExchanges = lazy(() => import('../pages/Exchanges/Promos'));

export const mapAuthPathsToSecureRouteComps = new Map([
  [ROUTES.Dashboard, Dashboard],
  [ROUTES.ChangePassword, ChangePassword],
  [ROUTES.AdminProfile, AdminProfile],
  [ROUTES.AddProduct, AddProduct],
  [ROUTES.ListProducts, ListProducts],
  [ROUTES.AddAwardCode, AddAwardCode],
  [ROUTES.ListAwardCodes, ListAwardCodes],
  [ROUTES.AddReward, AddReward],
  [ROUTES.ListRewards, ListRewards],
  [ROUTES.AddTobacconist, AddTobacconist],
  [ROUTES.ListTobacconist, ListTobacconists],
  [ROUTES.AddPromo, AddPromo],
  [ROUTES.ListPromos, ListPromos],
  [ROUTES.AddCategory, AddCategory],
  [ROUTES.ListProductsCategories, ListProductsCategories],
  [ROUTES.ListRewardsCategories, ListRewardsCategories],
  [ROUTES.CustomerHistory, CustomersExchanges],
  [ROUTES.PromosHistory, PromoExchanges],
  [ROUTES.CustomerLimits, CustomerLimits],
  [ROUTES.TobacconistLimits, TobacconistLimits],
]);

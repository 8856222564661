import {useState, createContext, PropsWithChildren} from 'react';
import {Auth} from './types';
import {
  useSignInMutation,
  isSuccessResponse,
  useGetAdminProfileQuery,
  useLogoutMutation,
  setAxiosToken,
} from '@/app/services/api';
import type {CustomSerializedError} from '@/app/services/api/types';
import {ROUTES} from '@/app/constants/routes';
import {useHistory} from 'react-router';
import {useAppDispatch} from '@/app/store/shared';
import {rememberMeStoreSelector, saveAuthState, deleteRememberMe} from '@/app/store/auth';

export const AuthContext = createContext<Auth>(null);

export default function AuthProvider({children}: PropsWithChildren<unknown>) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [signOut] = useLogoutMutation();
  const [signIn, {isLoading, isError, error}] = useSignInMutation();
  const {rememberMe, authState: authStateSelector} = rememberMeStoreSelector();
  const {data: adminProfile} = useGetAdminProfileQuery();
  const [authState, setAuthState] = useState<Omit<Auth, 'login' | 'logout' | 'checkRememberMe'>>(null);

  const login = async (body: Credentials) => {
    const result = await signIn(body);
    if (isSuccessResponse(result)) {
      setAxiosToken(result.data.token);

      setAuthState({
        isAuth: true,
        token: result.data.token,
        user: {id: result.data.id, name: result.data.name},
      });

      dispatch(
        saveAuthState({
          authState: {
            isAuth: true,
            token: result.data.token,
            user: {name: result.data.name, id: result.data.id},
          },
        }),
      );

      return true;
    }
    setAuthState(prev => ({...prev, error: error as CustomSerializedError}));
    return false;
  };

  const logout = async () => {
    dispatch(deleteRememberMe());
    await signOut();
    history.push('/');
  };

  const checkRememberMe = () => {
    const isAuth = authStateSelector.isAuth;
    if (isAuth) {
      if (adminProfile)
        setAuthState({
          isAuth,
          token: authStateSelector.token,
          user: {id: adminProfile.id, name: adminProfile.name},
        });
      setAuthState(authStateSelector);
      history.push(ROUTES.Dashboard);
    }
    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        isLoading,
        isError,
        error: error as CustomSerializedError,
        login,
        logout,
        checkRememberMe,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

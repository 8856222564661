import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {useAppSelector} from '../shared';
import type {InitialState} from './types';

const rememberMeSlice = createSlice({
  name: 'rememberMe',
  initialState: {
    rememberMe: false,
    authState: {
      isAuth: false,
      token: '',
      user: {
        name: '',
        id: '',
      },
    },
  } as InitialState,
  reducers: {
    saveRememberMe: (state, action: PayloadAction<Omit<InitialState, 'authState'>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    saveAuthState: (state, action: PayloadAction<Omit<InitialState, 'rememberMe'>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    deleteRememberMe: () => {
      return {
        rememberMe: false,
        authState: {
          isAuth: false,
          token: '',
          user: {
            name: '',
            id: '',
          },
        },
      };
    },
  },
});

export const {saveRememberMe, saveAuthState, deleteRememberMe} = rememberMeSlice.actions;

export const rememberMeStoreSelector = () => useAppSelector(state => state.rememberMe);

export const rememberMeSelector = () => useAppSelector(state => state.rememberMe.rememberMe);

export const rememberMeAuthStateSelector = () => useAppSelector(state => state.rememberMe.authState);

export default rememberMeSlice.reducer;

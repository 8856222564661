import React, {useEffect, useState, useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {Provider} from 'react-redux';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/es/integration/react';
import {ThemeSwitcherProvider} from 'react-css-theme-switcher';
import {useTranslation} from 'react-i18next';
import {ROUTES} from '@/app/constants/routes';
import AppRoutes from './AppRoutes';
import Navbar from '@/app/components/Layout/shared/Navbar';
import Sidebar from '@/app/components/Layout/shared/Sidebar';
import Footer from '@/app/components/Layout/shared/Footer';
import AuthProvider from '@/app/contexts/Auth';
import store from '@/app/store';
import './App.scss';

const persistor = persistStore(store);

const themes = {
  light: '/static/light.css',
  dark: '/static/dark.css',
};

export default function App() {
  const [isFullPageLayout, setIsFullPageLayout] = useState<boolean>();
  const {i18n} = useTranslation();
  const location = useLocation();

  const onRouteChanged = useCallback(() => {
    const body = document.querySelector('body');
    if (location.pathname === '/layout/en') {
      body.classList.add('ltr');
      i18n.changeLanguage('en');
    } else {
      body.classList.remove('ltr');
      i18n.changeLanguage('br');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      ROUTES.Login,
      ROUTES.Landing,
      ROUTES.Error404,
      ROUTES.Error500,
      ROUTES.ResetPasswordEmail,
      ROUTES.CheckSignedUrlResetPass,
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (location.pathname === fullPageLayoutRoutes[i]) {
        setIsFullPageLayout(true);
        document.querySelector('.page-body-wrapper')?.classList?.add('full-page-wrapper');
        break;
      } else if (location.pathname.includes('/pages/verify/')) {
        setIsFullPageLayout(true);
        document.querySelector('.page-body-wrapper')?.classList?.add('full-page-wrapper');
        break;
      } else {
        setIsFullPageLayout(false);
        document.querySelector('.page-body-wrapper')?.classList?.remove('full-page-wrapper');
      }
    }
  }, [location]);

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  const navbarComponent = !isFullPageLayout ? <Navbar /> : '';
  const sidebarComponent = !isFullPageLayout ? <Sidebar /> : '';
  const footerComponent = !isFullPageLayout ? <Footer /> : '';
  return (
    <ThemeSwitcherProvider defaultTheme="light" themeMap={themes}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY}
        language="pt-BR"
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
      >
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AuthProvider>
              <div className="container-scroller">
                {sidebarComponent}
                <div className="container-fluid page-body-wrapper">
                  {navbarComponent}
                  <div className="main-panel">
                    <div className="content-wrapper">
                      <AppRoutes />
                    </div>
                    {footerComponent}
                  </div>
                </div>
              </div>
            </AuthProvider>
          </PersistGate>
        </Provider>
      </GoogleReCaptchaProvider>
    </ThemeSwitcherProvider>
  );
}

import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Collapse} from 'react-bootstrap';
import {Trans} from 'react-i18next';
import {useThemeSwitcher} from 'react-css-theme-switcher';

import logo from '@/assets/images/logo.png';
import logoDark from '@/assets/images/logo-dark.png';
import {ROUTES} from '@/app/constants/routes';

export default function Sidebar() {
  const {currentTheme} = useThemeSwitcher();
  const location = useLocation();
  const [state, setMenuState] = useState<{[menuState: string]: boolean}>({});

  const toggleMenuState = (menuState: string) => {
    if (state[menuState]) {
      setMenuState(prev => ({...prev, [menuState]: false}));
    } else if (Object.keys(state).length === 0) {
      setMenuState(prev => ({...prev, [menuState]: true}));
    } else {
      Object.keys(state).forEach(i => {
        setMenuState(prev => ({...prev, [i]: false}));
      });
      setMenuState(prev => ({...prev, [menuState]: true}));
    }
  };

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  const onRouteChanged = () => {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(state).forEach(i => {
      setMenuState(prev => ({...prev, [i]: false}));
    });
  };

  const isPathActive = (path: string) => location.pathname.startsWith(path);

  useEffect(() => {
    onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach(el => {
      el.addEventListener('mouseover', () => {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', () => {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }, []);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
        <img width="55px" src={currentTheme === 'light' ? logoDark : logo} alt="logo" />
      </div>

      <ul className="nav">
        <li className="nav-item nav-category">
          <span className="nav-link">
            <Trans>Navegação</Trans>
          </span>
        </li>

        <li className={isPathActive('/pages/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <Link className="nav-link" to="/pages/dashboard">
            <span className="menu-icon">
              <i className="mdi mdi-speedometer" />
            </span>
            <span className="menu-title">
              <Trans>Painel</Trans>
            </span>
          </Link>
        </li>

        <li className={isPathActive('/pages/products') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div
            className={state.productMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
            onClick={() => toggleMenuState('productMenuOpen')}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-tablet-ipad" />
            </span>
            <span className="menu-title">
              <Trans>Produtos</Trans>
            </span>
            <i className="menu-arrow" />
          </div>
          <Collapse in={state.productMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive('/pages/products/add') ? 'nav-link active' : 'nav-link'}
                    to="/pages/products/add"
                  >
                    <Trans>Novo Produto</Trans>
                  </Link>
                </li>

                <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive('/pages/products') ? 'nav-link active' : 'nav-link'}
                    to="/pages/products"
                  >
                    <Trans>Lista de Produtos</Trans>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>

        <li className={isPathActive('/pages/rewards') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div
            className={state.RewardMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
            onClick={() => toggleMenuState('RewardMenuOpen')}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-tablet-ipad" />
            </span>
            <span className="menu-title">
              <Trans>Prêmios</Trans>
            </span>
            <i className="menu-arrow" />
          </div>
          <Collapse in={state.RewardMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive('/pages/rewards/add') ? 'nav-link active' : 'nav-link'}
                    to="/pages/rewards/add"
                  >
                    <Trans>Novo Prêmio</Trans>
                  </Link>
                </li>

                <li className="nav-item">
                  {' '}
                  <Link className={isPathActive('/pages/rewards') ? 'nav-link active' : 'nav-link'} to="/pages/rewards">
                    <Trans>Lista de Prêmios</Trans>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>

        <li className={isPathActive('/pages/award-code') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div
            className={state.AwardCodeMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
            onClick={() => toggleMenuState('AwardCodeMenuOpen')}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-tablet-ipad" />
            </span>

            <span className="menu-title">
              <Trans>Código premiado</Trans>
            </span>

            <i className="menu-arrow" />
          </div>

          <Collapse in={state.AwardCodeMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive('/pages/award-code/add') ? 'nav-link active' : 'nav-link'}
                    to="/pages/award-code/add"
                  >
                    <Trans>Novo Código</Trans>
                  </Link>
                </li>

                <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive('/pages/award-codes') ? 'nav-link active' : 'nav-link'}
                    to="/pages/award-codes"
                  >
                    <Trans>Lista de Códigos</Trans>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>

        {/* <li className={isPathActive('/pages/tobacconists') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div
            className={state.tobacconistMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
            onClick={() => toggleMenuState('tobacconistMenuOpen')}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-store" />
            </span>
            <span className="menu-title">
              <Trans>Tabacarias</Trans>
            </span>
            <i className="menu-arrow" />
          </div>
          <Collapse in={state.tobacconistMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive('/pages/tobacconists/add') ? 'nav-link active' : 'nav-link'}
                    to="/pages/tobacconists/add"
                  >
                    <Trans>Nova Tabacaria</Trans>
                  </Link>
                </li>
              </ul>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive('/pages/tobacconists') ? 'nav-link active' : 'nav-link'}
                    to="/pages/tobacconists"
                  >
                    <Trans>Lista de Tabacaria</Trans>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li> */}

        {/* <li className={isPathActive('/pages/promos') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div
            className={state.promoMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
            onClick={() => toggleMenuState('promoMenuOpen')}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-gift" />
            </span>
            <span className="menu-title">
              <Trans>Campanhas</Trans>
            </span>
            <i className="menu-arrow" />
          </div>
          <Collapse in={state.promoMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive('/pages/promos/add') ? 'nav-link active' : 'nav-link'}
                    to="/pages/promos/add"
                  >
                    <Trans>Nova Campanha</Trans>
                  </Link>
                </li>
                
              </ul>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {' '}
                  <Link className={isPathActive('/pages/promos') ? 'nav-link active' : 'nav-link'} to="/pages/promos">
                    <Trans>Lista de Campanhas</Trans>
                  </Link>
                </li>
                
              </ul>
            </div>
          </Collapse>
        </li>
         */}

        <li className={isPathActive('/pages/categories') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div
            className={state.categoriesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
            onClick={() => toggleMenuState('categoriesMenuOpen')}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-tag" />
            </span>
            <span className="menu-title">
              <Trans>Categorias</Trans>
            </span>
            <i className="menu-arrow" />
          </div>
          <Collapse in={state.categoriesMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive('/pages/categories/add') ? 'nav-link active' : 'nav-link'}
                    to="/pages/categories/add"
                  >
                    <Trans>Nova Categoria</Trans>
                  </Link>
                </li>
              </ul>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive('/pages/categories/products') ? 'nav-link active' : 'nav-link'}
                    to="/pages/categories/products"
                  >
                    <Trans>Produtos</Trans>
                  </Link>
                </li>
              </ul>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive('/pages/categories/rewards') ? 'nav-link active' : 'nav-link'}
                    to="/pages/categories/rewards"
                  >
                    <Trans>Prêmios</Trans>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>

        <li className={isPathActive('/pages/exchanges') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div
            className={state.exchangesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
            onClick={() => toggleMenuState('exchangesMenuOpen')}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-swap-horizontal" />
            </span>
            <span className="menu-title">
              <Trans>Trocas</Trans>
            </span>
            <i className="menu-arrow" />
          </div>
          <Collapse in={state.exchangesMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive('/pages/exchanges/customers') ? 'nav-link active' : 'nav-link'}
                    to="/pages/exchanges/customers"
                  >
                    <Trans>Clientes</Trans>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>

        <li className={isPathActive('/pages/limits') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div
            className={state.limitsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
            onClick={() => toggleMenuState('limitsMenuOpen')}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-format-horizontal-align-right" />
            </span>
            <span className="menu-title">
              <Trans>Limites</Trans>
            </span>
            <i className="menu-arrow" />
          </div>
          <Collapse in={state.limitsMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive('/pages/limits/customer') ? 'nav-link active' : 'nav-link'}
                    to="/pages/limits/customer"
                  >
                    <Trans>Clientes</Trans>
                  </Link>
                </li>

                {/* <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive('/pages/limits/tobacconist') ? 'nav-link active' : 'nav-link'}
                    to="/pages/limits/tobacconist"
                  >
                    <Trans>Tabacarias</Trans>
                  </Link>
                </li>
                 */}
              </ul>
            </div>
          </Collapse>
        </li>

        <li className="nav-item nav-category">
          <span className="nav-link">
            <Trans>Mais</Trans>
          </span>
        </li>

        <li className={isPathActive('/pages/admin') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div
            className={state.adminMenuOpen ? 'nav-link menu-expanded' : 'nav-link'}
            onClick={() => toggleMenuState('adminMenuOpen')}
            data-toggle="collapse"
          >
            <span className="menu-icon">
              <i className="mdi mdi-security" />
            </span>
            <span className="menu-title">
              <Trans>Administrador</Trans>
            </span>
            <i className="menu-arrow" />
          </div>
          <Collapse in={state.adminMenuOpen}>
            <div>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {' '}
                  <Link
                    className={isPathActive(ROUTES.AdminProfile) ? 'nav-link active' : 'nav-link'}
                    to={ROUTES.AdminProfile}
                  >
                    Perfil
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
      </ul>
    </nav>
  );
}

import React, {useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import {Trans} from 'react-i18next';
import {useThemeSwitcher} from 'react-css-theme-switcher';
import {useRoutesHistory} from '@/app/constants/routes';
import {useLogoutMutation} from '@/app/services/api';
import useAuth from '@/app/hooks/useAuth';

import ziggyFace from '@/assets/images/ziggy-face.jpg';

export default function Navbar() {
  // const [english, setEnglish] = useState(false);
  // const {i18n} = useTranslation();
  const history = useRoutesHistory();
  const {user, logout} = useAuth();
  const {switcher, themes} = useThemeSwitcher();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  };

  const toggleDarkMode = () => {
    switcher({theme: isDarkMode ? themes.light : themes.dark});
    setIsDarkMode(previous => !previous);
  };

  // useEffect(() => {
  //   i18n.changeLanguage(english ? 'en' : 'br');
  // }, [english]);

  return (
    <nav className="navbar p-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <button
          className="navbar-toggler align-self-center"
          type="button"
          onClick={() => document.body.classList.toggle('sidebar-icon-only')}
        >
          <span className="mdi mdi-menu" />
        </button>
        <ul className="navbar-nav navbar-nav-right">
          <Dropdown alignRight as="li" className="nav-item d-none d-lg-block">
            <Dropdown.Toggle className="nav-link btn btn-success create-new-button no-caret">
              + <Trans>Novo</Trans>
            </Dropdown.Toggle>
            <Dropdown.Menu className="navbar-dropdown preview-list create-new-dropdown-menu">
              <h6 className="p-3 mb-0">
                <Trans>Ações</Trans>
              </h6>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => history.push('/pages/rewards/add')} className="preview-item">
                <div className="preview-thumbnail">
                  <div className="preview-icon bg-dark rounded-circle">
                    <i className="mdi mdi-file-outline text-primary" />
                  </div>
                </div>
                <div className="preview-item-content">
                  <p className="preview-subject mb-1">
                    <Trans>Prêmio</Trans>
                  </p>
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => history.push('/pages/products/add')} className="preview-item">
                <div className="preview-thumbnail">
                  <div className="preview-icon bg-dark rounded-circle">
                    <i className="mdi mdi-web text-info" />
                  </div>
                </div>
                <div className="preview-item-content">
                  <p className="preview-subject mb-1">
                    <Trans>Produto</Trans>
                  </p>
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
              {/* <Dropdown.Item onClick={() => history.push('/pages/tobacconists/add')} className="preview-item">
                <div className="preview-thumbnail">
                  <div className="preview-icon bg-dark rounded-circle">
                    <i className="mdi mdi-layers text-danger" />
                  </div>
                </div>
                <div className="preview-item-content">
                  <p className="preview-subject mb-1">
                    <Trans>Tabacaria</Trans>
                  </p>
                </div>
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
          <li className="nav-item d-none d-lg-block">
            <a className="nav-link" onClick={toggleDarkMode}>
              <i className="mdi mdi-theme-light-dark" />
            </a>
          </li>
          <Dropdown alignRight as="li" className="nav-item">
            <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
              <div className="navbar-profile">
                <img className="img-xs rounded-circle" src={ziggyFace} alt="profile" />
                <p className="mb-0 d-none d-sm-block navbar-profile-name">
                  <Trans>{user?.name ?? 'ADMIN'}</Trans>
                </p>
                <i className="mdi mdi-menu-down d-none d-sm-block" />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
              <h6 className="p-3 mb-0">
                <Trans>Perfil</Trans>
              </h6>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => history.push('/pages/admin/change-password')} className="preview-item">
                <div className="preview-thumbnail">
                  <div className="preview-icon bg-dark rounded-circle">
                    <i className="mdi mdi-onepassword  text-info" />
                  </div>
                </div>
                <div className="preview-item-content">
                  <p className="preview-subject mb-1">
                    <Trans>Trocar Senha</Trans>
                  </p>
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => logout()} className="preview-item">
                <div className="preview-thumbnail">
                  <div className="preview-icon bg-dark rounded-circle">
                    <i className="mdi mdi-logout text-danger" />
                  </div>
                </div>
                <div className="preview-item-content">
                  <p className="preview-subject mb-1">
                    <Trans>Sair</Trans>
                  </p>
                </div>
              </Dropdown.Item>
              <Dropdown.Divider />
              {/* <Dropdown.Item onClick={() => setEnglish(!english)} className="preview-item">
                <div className="preview-thumbnail">
                  <div className="preview-icon bg-dark rounded-circle">
                    <i className="mdi mdi-web  text-info" />
                  </div>
                </div>
                <div className="preview-item-content">
                  <p className="preview-subject mb-1">
                    <Trans>English</Trans>
                  </p>
                </div>
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="mdi mdi-format-line-spacing" />
        </button>
      </div>
    </nav>
  );
}
